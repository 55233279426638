import React from 'react';
import './Break.css';

function Break() {
  return (
    <div className='break' id="break">
    </div>
  );
}

export default Break;
